import { createEventHook, createGlobalState } from '@vueuse/core';

export const useGlobalStore = createGlobalState(
  () => {
    const headerSearchClickedEvent = createEventHook();

    const headerSearchClicked = () => {
      headerSearchClickedEvent.trigger();
    };

    return {
      onHeaderSearchClicked: headerSearchClickedEvent.on,
      headerSearchClicked,
    };
  },
);